<template>
  <div class="container">
    <div class="page-header">
      <span v-if="query === ''" class="text-truncate">Showing {{items.length}} of {{meta.total}} tags</span>
      <span
        v-else
        data-test="txt-search-result"
        class="text-truncate">
        Found {{meta.total}} tags that match '{{query}}'
      </span>
      <router-link
        v-if="can(uiPermissions.TAGS_CREATE)"
        to="/tags/new"
        data-test="btn-new-tag"
        class="btn btn-primary ml-auto">
        New tag
      </router-link>
    </div>
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="table-responsive">
            <table data-test="table-tags" class="table table-hover table-outline table-vcenter text-nowrap card-table">
              <thead>
                <tr>
                  <th class="w-1"></th>
                  <th>Name</th>
                  <th>Category</th>
                  <th>Sub-tags</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in items" :key="item.id">
                  <td class="text-center">
                    <span class="avatar" :style="`background-image: url(${identicon(item.id)});`"></span>
                  </td>
                  <td>
                    <router-link v-if="can(uiPermissions.TAGS_VIEW)" :to="`/tags/${item.id}`">{{item.name}}</router-link>
                    <span v-else>{{item.name}}</span>
                  </td>
                  <td>{{item.category.name}}</td>
                  <td>
                    <custom-tree :data="item.sub_tags" :options="treeOptions"/>
                  </td>
                  <td class="text-center">
                    <div v-if="can([uiPermissions.TAGS_VIEW])" class="item-action dropdown">
                      <a
                        tabindex="0"
                        data-toggle="dropdown"
                        data-test="link-dropdown"
                        class="icon"><i class="fe fe-more-vertical"></i></a>
                      <div class="dropdown-menu dropdown-menu-right">
                        <router-link
                          v-if="can(uiPermissions.TAGS_VIEW)"
                          :to="`/tags/${item.id}`"
                          data-test="link-edit"
                          class="dropdown-item">
                          <i class="dropdown-icon fe fe-edit"></i> Edit
                        </router-link>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr v-if="items.length === 0">
                  <td colspan="5">There are no results matching your criteria.</td>
                </tr>
              </tbody>
            </table>
          </div>
          <pagination
            :currentPage="page"
            :totalPages="meta.totalPages"
            @goToPage="goToPage"></pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import CustomTree from '@/components/CustomTree.vue';
import Pagination from '@/components/Pagination.vue';
import list from '@/mixins/list';
import {tags} from '@/services';

export default {

  components: {
    [CustomTree.name]: CustomTree,
    Pagination,
  },
  mixins: [
    list,
  ],
  data() {
    return {
      treeOptions: {
        propertyNames: {
          children: 'sub_tags',
          text: 'name',
        },
      },
    };
  },
  methods: {
    fetchData(page) {
      return tags.getByParameters({
        limit: this.limit,
        page,
        query: this.query,
        with: 'category',
        subTags: 1,
      });
    },
    async handleDelete(item) {
      if (window.confirm('Deleting tag cannot be undone. Are you sure that you want to delete this tag?')) {
        await tags.deleteById(item.id);
        this.refresh();
      }
    },
  },
};
</script>
